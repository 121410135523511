import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import Flickity from "react-flickity-component"

import SocialMediaPost from "./SocialMediaPost"
import SocialMediaPostPlaceholder from "./SocialMediaPostPlaceholder"
import ErrorMessage from "./ErrorMessage"

import getInstagramPosts from "../utils/get-instagram-posts"
import getUserAgent from "../utils/get-user-agent"
import flickityA11y from "../utils/flickity-a11y"

import instagramIcon from "../images/socialmedia-icons/cib-instagram.svg"
import arrowSvg from "../images/arrow-white.svg"

const Wrapper = styled.section`
  position: relative;
  margin-top: 2rem;
  clear: both;

  h2 {
    margin: 0;
    padding: 0 1rem;
    font-family: ${props => props.theme.headingFont};
  }

  ul {
    list-style: none;
    margin: 1rem 0;
    padding: 0 0 0 1rem;
    overflow-x: ${props => props.isLoading ? "hidden" : "auto"};
    white-space: nowrap;
  }

  li,
  .article {
    display: inline-block;
    width: 75%;
    margin-right: 1rem;
    white-space: normal;
    vertical-align: top;
  }

  .logo {
    max-height: 1rem;
    opacity: 0.5;
    margin-right: 0.2rem;

    &:last-child {
      margin-right: 0.7rem;
    }
  }

  @media (min-width: 420px) {
    li,
    .article {
      width: 67%;
    }
  }

  @media (min-width: 580px) {
    li,
    .article {
      width: 60%;
    }
  }

  @media (min-width: 700px) {
    li,
    .article {
      width: 40%;
    }
  }

  @media (min-width: 940px) {
    li,
    .article {
      width: 30%;
    }
  }

  @media (min-width: 1000px) {
    width: calc(100% - 28rem);
    margin-top: 0;
    padding-top: 3.75rem;
    float: right;
    clear: none;

    li,
    .article {
      width: 45%;
    }
  }

  @media (min-width: 1200px) {
    width: calc(100% - 32.25rem);

    li,
    .article {
      width: 42%;
    }
  }

  @media (min-width: 1500px) {
    li,
    .article {
      width: 30%;
    }
  }

  @media (min-width: 1700px) {
    li,
    .article {
      width: 28%;
    }
  }
`

const StyledFlickity = styled(Flickity)`
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  position: relative;
  width: 100%;
  margin: auto 0;
  padding: 0.6rem 1rem;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  .flickity-slider {
    height: 100%;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-prev-next-button {
    position: absolute;
    top: 0;
    width: 1.75rem;
    height: 100%;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;

    ::before {
      content: "";
      display: block;
      border: 0.125rem solid white;
      border-radius: 50%;
      width: calc(100% - 0.25rem);
      padding-bottom: calc(100% - 0.25rem);
      background: rgba(0, 0, 0, 0.35) url(${arrowSvg}) no-repeat center;
      background-size: contain;
      box-shadow:
        0 -0.1875rem 0.3125rem rgba(102, 102, 102, 0.3);
    }

    svg {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    left: 0.25rem;
    transform: rotate(180deg);
    /* box-shadow: 0 -0.1875rem 0.3125rem rgba(102, 102, 102, 0.3); */
  }

  .flickity-prev-next-button.next {
    right: 0.25rem;
    /* box-shadow: 0 0.1875rem 0.3125rem rgba(102, 102, 102, 0.3); */
  }

  .flickity-prev-next-button:disabled {
    display: none;
  }
`

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 1rem 0 0 1rem;
  border: 1px solid rgba(160, 100, 180, 0.1);
  border-radius: 0.3125rem;
  backdrop-filter: blur(3px);
`

export default function SocialMediaPosts({ lang }) {
  const intl = useIntl()
  const ua = getUserAgent()
  const [isLoading, setIsLoading] = useState(true)
  const [posts, setPosts] = useState(Array(4).fill(0).map((_, i) => ({ id: i })))
  const [hasError, setHasError] = useState(false)
  const [isFlickity, setIsFlickity] = useState(null)
  const flickityOptions = isFlickity ? {
    pageDots: false,
    draggable: false,
    contain: true,
    cellAlign: "left",
    groupCells: true,
    on: {
      ready: flickityA11y,
      change: () => flickityA11y(false),
    },
  } : null

  const getSocialMediaPosts = () => {
    setIsLoading(true)
    setHasError(false)

    getInstagramPosts(lang)
      .then((igPosts) => {
        igPosts.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)

        setPosts(igPosts)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setHasError(true)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (typeof window !== "undefined" && isFlickity === null) {
      setIsFlickity(
        (!ua.isMobile && ua.os.name !== "Mac OS") ||
        (ua.os.name === "iOS" && Number(ua.os.version.split(".")[0]) <= 12)
      )
    }
  }, [])

  useEffect(() => {
    getSocialMediaPosts()
  }, [])

  return (
    <Wrapper isLoading={isLoading}>
      <h2>
        <img src={instagramIcon} alt="Instagram" className="logo" />
        #{intl.formatMessage({ id: "siteTitle" }).toLowerCase().replace(/ /g, "")}
      </h2>
      {isLoading &&
        <span className="structural">{intl.formatMessage({ id: "loading" })}</span>
      }
      {hasError ?
        <StyledErrorMessage onRetry={getSocialMediaPosts} className="article" />
      :
        <>
          {isFlickity && !isLoading ?
            <StyledFlickity options={flickityOptions}>
              {posts.map(({ id, ...post }) => (
                <SocialMediaPost key={id} lang={lang} post={post} className="article" />
              ))}
            </StyledFlickity>
          :
            <ul aria-hidden={isLoading}>
              {posts.map(({ id, ...post }) => (
                <li key={id}>
                  {isLoading ?
                    <SocialMediaPostPlaceholder />
                  :
                    <SocialMediaPost lang={lang} post={post} />
                  }
                </li>
              ))}
            </ul>
          }
        </>
      }
    </Wrapper>
  )
}
