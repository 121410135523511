import isProd from "./is-prod"

if (typeof window !== "undefined") {
  require("whatwg-fetch")
}

export default function getInstagramPosts(lang) {
  const url = isProd() ?
    `/.netlify/functions/get_instagram_posts?lang=${lang}` :
    `/assets/social-media-posts/instagram-posts-${lang}.json`

  return window.fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
  })
    .then(res => res.json())
    .then((res) =>
      res.data.map((post) => ({
        id: post.id,
        text: post.caption,
        media_url: post.media_url,
        video_thumbnail: post.thumbnail_url,
        media_type: post.media_type,
        link: post.permalink,
        timestamp: post.timestamp,
        platform: "Instagram",
      }))
    )
}
