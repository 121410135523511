import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import instagramIcon from "../images/socialmedia-icons/cib-instagram.svg"
import fbIcon from "../images/socialmedia-icons/cib-facebook-f.svg"

const Wrapper = styled.section`  
  padding: 0.2rem 1.6rem;
  border: 1px solid rgba(160, 100, 180, 0.1);
  border-radius: 5px;
  backdrop-filter: blur(3px);

  ul {
    list-style-type: none;
    margin: 1.6rem 0;
    padding: 0;
    display: flex;
    flex: row nowrap;
    justify-content: space-around;

    li {
      a {
        display: block;
      }
      img {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
`

export default function SocialMediaContactCard({ text }) {
  const intl = useIntl()

  return (
    <Wrapper>
      <div>
        {text && <div dangerouslySetInnerHTML={{ __html: get(text, "html") }} />}
        <ul>
          <li>
            <a href={intl.formatMessage({ id: "instagramLink" })}>
              <img src={instagramIcon} alt="Instagram" />
            </a>
          </li>
          <li>
            <a href={intl.formatMessage({ id: "facebookLink" })}>
              <img src={fbIcon} alt="Facebook" />
            </a>
          </li>
        </ul>
      </div>
    </Wrapper>
  )
}
