import React, { useEffect } from "react"
import styled from "styled-components"
import Flickity from "react-flickity-component"
import get from "lodash/get"

import flickityA11y from "../utils/flickity-a11y"

const C_HIGHLIGHT = "#00809b"

const StyledFlickity = styled(Flickity)`
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-page-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 1.25rem 0 1rem;
    list-style: none;
    cursor: pointer;

    .dot {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 0.3125rem;
      border-radius: 50%;
      background: ${props => props.theme.roomColors[8].lighter};
      text-indent: -9999px;
      transition: background 0.2s ease;

      &.is-selected {
        background: ${props => props.theme.roomColors[8].base};
      }
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 0;
`

const Content = styled.div`
  position: relative;
  border: 1px solid white;
  border-radius: 0.3125rem;
  box-shadow: 0 0.625rem 0.625rem rgba(102, 102, 102, 0.15);
  background-color: rgba(255, 255, 255, 0.6);
`

const ImgWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const Eyecatcher = styled.h2`
  position: absolute;
  top: 0%;
  left: -1%;
  z-index: 9;
  margin: 0;
  padding: 0.6rem 0.8rem;
  border-radius: 0.3125rem;
  background: ${C_HIGHLIGHT};
  font: 1rem/1.5 ${props => props.theme.headingFont};
  color: white;
  transform: rotate(-15deg);

  :after {
    content: "";
    position: absolute;
    left: 5%;
    top: 100%;
    line-height: 0;
    border: 0.625rem solid transparent;
    border-top: 1.875rem solid ${C_HIGHLIGHT};
    transform-origin: top right;
    transform: rotate(25deg);
    z-index: 8;
  }
`

const Title = styled.h2`
  margin: 1rem 0 0;
  padding: 0 1.2rem;
  font: 1.125rem/1.5 ${props => props.theme.headingFont};
`

const Message = styled.section`
  padding: 0rem 1.2rem;
`

let getInvolvedFlkty

export default function CallToActionPosts({ feed, ...props }) {
  if (!feed || !feed.length) {
    return null
  }

  const flickityOptions = {
    prevNextButtons: false,
    groupCells: 1,
    contain: true,
    adaptiveHeight: true,
    cellAlign: 'left',
    on: {
      ready: () => flickityA11y(true, false, true),
      change: () => flickityA11y(false, false, true),
    },
  }

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== "undefined" && getInvolvedFlkty) {
        getInvolvedFlkty.resize()
      }
    })
  }, [])

  return (
    <StyledFlickity
      options={flickityOptions}
      flickityRef={c => getInvolvedFlkty = c}
      {...props}
    >
      {feed.map(({ title, text, image }) => (
        <Wrapper key={title}>
          <Content>
            {title && image && <Eyecatcher>{title}</Eyecatcher>}
            {title && !image && <Title>{title}</Title>}
            {image && <ImgWrap style={{ backgroundImage: `url(${encodeURI(image)}?nf_resize=fit&w=828)` }} />}
            <Message dangerouslySetInnerHTML={{ __html: get(text, "html") }} />
          </Content>
        </Wrapper>
      ))}
    </StyledFlickity>
  )
}
