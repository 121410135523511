import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import TimeAgo from "javascript-time-ago"
import sv from "javascript-time-ago/locale/sv"
import en from "javascript-time-ago/locale/en"

import RoomMock from "../components/RoomMock"
import CallToActionPosts from "../components/CallToActionPosts"
import SocialMediaContactCard from "../components/SocialMediaContactCard"
import SocialMediaPosts from "../components/SocialMediaPosts"
import ChitChatTeaser from "../components/ChitChatTeaser"

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(sv)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  padding-top: 4rem;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
  gap: 2rem;
  align-items: center;

  @media (min-width: 1000px) {
    flex-direction: row;
    gap: unset;
    align-items: initial;
  }
`

const CallToActionPostsWrapper = styled.section`
  margin-top: -3rem;
`

const ColumnLeft = styled.div`
  width: 28rem; 
  max-width: 100%;

  @media (min-width: 1200px) {
    margin-left: 1.5rem;
  }
`

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  max-width: 50rem;
  
  @media (min-width: 1000px) {
    margin-top: -1rem;
  }  
`

export default function RoomTemplate8({
  data: { getInvolvedYaml: { social_media_text, news_feed }, ciceronYaml },
  pageContext: { lang, pagePath, translations },
}) {
  const intl = useIntl()

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={8}
      title={intl.formatMessage({ id: "roomHeading8" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning10_Var_med_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <Wrapper>
        <ColumnLeft>
          <CallToActionPostsWrapper>
            <CallToActionPosts feed={news_feed} />
          </CallToActionPostsWrapper>          
        </ColumnLeft>
        <ColumnRight>
          <SocialMediaContactCard text={social_media_text} />
          <h2>ChitChat</h2>
          <ChitChatTeaser lang={lang} />
        </ColumnRight>        
        {/* 
          <SocialMediaPosts lang={lang} />
        */}        
      </Wrapper>
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    getInvolvedYaml(lang: { eq: $lang }) {
      social_media_text {
        html
      }
      news_feed {
        title
        image
        text {
          html
        }
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-8" }) {
      text
      sound
    }
  }
`
