import React, { useState, useRef } from "react"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import ReactTimeAgo from "react-time-ago"

import Logo from "./LogoFull"

import getUserAgent from "../utils/get-user-agent"
import nl2br from "../utils/nl2br"

import instagramIcon from "../images/socialmedia-icons/cib-instagram-light.svg"
import fbIcon from "../images/socialmedia-icons/cib-facebook-f-light-circle.svg"
import playIcon from "../images/socialmedia-icons/sm-icon-play.svg"

const Wrapper = styled.article`
  border: 1px solid white;
  background: white;

  p {
    margin: 0;
  }
`

const ImageLink = styled.a`
  position: relative;
  display: block;
  padding-top: 100%;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${props => props.isIE ? `
    height: auto;
  ` : `
    height: 100%;
    object-fit: cover;
  `}
`

const PlayButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding: 100% 0 0;
  border: 0;
  background: none;

  ::before {
    content: url(${playIcon});
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%) scale(1);
    transition: transform .2s ease-in-out;
  }

  :hover::before {
    transform: translate(-50%, -50%) scale(1.2);
  }
`

const VideoWrapper = styled.div`
  ${props => props.show ? `
    position: relative;
    height: 0;
    padding-top: 100%;
  ` : `
    display: none;
  `}
`

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SocialLogoWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background: rgba(0, 0, 0, 0.35);
`

const SocialLogo = styled.img`
  display: block;
  width: 0.875rem;
  height: 0.875rem;
`

const Inner = styled.div`
  padding: 1rem;

  address a {
    text-decoration: none;
    color: inherit;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  address {
    line-height: 1;

    a {
      font: 600 1rem/1 ${props => props.theme.secondaryFont};
    }
  }

  time {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1;
    color: #666;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.625rem;
  border-radius: 50%;
  background: #e20000;
`

const StyledLogoFull = styled(Logo)`
  width: 1.5rem;
  height: 1.5rem;
`

const Text = styled.p`
  overflow: hidden;

  ${props => props.isIE ? `
    max-height: 7.5rem;
  ` : `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  `}
`

const LinkWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;

  a {
    text-decoration: none;
  }
`

export default function SocialMediaPost({ lang, post, ...props }) {
  const intl = useIntl()
  const videoRef = useRef()
  const [showVideo, setShowVideo] = useState(false)
  const { id, platform, text, media_url, media_type, video_thumbnail, link, timestamp } = post
  const ua = getUserAgent()
  const isIE = ua.browser.name === "IE"

  const handlePlayVideo = () => {
    setShowVideo(true)
    const video = videoRef.current
    video.src = media_url
    video.play()
  }

  const socialLogo = (
    <SocialLogoWrapper>
      <SocialLogo
        src={platform === "Facebook" ? fbIcon : instagramIcon}
        alt={`${platform} ${intl.formatMessage({ id: "logotype" })}`}
      />
    </SocialLogoWrapper>
  )

  return (
    <Wrapper {...props}>
      {media_type === "VIDEO" ?
        <>
          <VideoWrapper show={showVideo}>
            <Video ref={videoRef} controls>
              {intl.formatMessage({ id: "noVideoSupport" })}
            </Video>
          </VideoWrapper>
          {!showVideo &&
            <PlayButton onClick={handlePlayVideo}>
              <Image src={video_thumbnail} alt="" isIE={isIE} />
              <span className="structural">
                {intl.formatMessage({ id: "play" })} {intl.formatMessage({ id: "video" })}
              </span>
              {socialLogo}
            </PlayButton>
          }
        </>
      :
        <ImageLink href={link}>
          <Image src={media_url} alt="" isIE={isIE} />
          {socialLogo}
        </ImageLink>
      }
      <Inner>
        <Header>
          <LogoWrapper>
            <StyledLogoFull
              lang={lang}
              mini
              color="#e20000"
              baseColor="white"
            />
          </LogoWrapper>
          <div>
            <address>
              <a
                href={intl.formatMessage({ id: platform === "Facebook" ? "facebookLink" : "instagramLink" })}
                rel="author"
              >
                {intl.formatMessage({ id: "siteTitle" })}
              </a>
            </address>
            <ReactTimeAgo date={new Date(timestamp.split("+")[0])} locale={lang} />
          </div>
        </Header>
        {text &&
          <Text isIE={isIE} dangerouslySetInnerHTML={{ __html: nl2br(text) }} />
        }
        <LinkWrapper>
          <a href={link}>{intl.formatMessage({ id: "socialMediaLinkText" })} {platform}</a>
        </LinkWrapper>
      </Inner>
    </Wrapper>
  )
}
