import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ChitChatBubble from "./ChitChatBubble"

import { useModal } from "../hooks/modal"
import getChitChatMessages from "../utils/get-chitchat-messages"

import chatIcon from "../images/chat-icon.svg"

const Wrapper = styled.section`  
  position: relative;  
  padding: 0.75rem 1rem 0;
  border: 1px solid rgba(160,100,180,0.1);
  border-radius: 0.3125rem;
  overflow: hidden;
  max-height: 30rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const Overlay = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 60%;
  border-radius: 0 0 0.3125rem 0.3125rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
`

const Button = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1.125rem;
  left: 50%;
  padding: 0.6875rem 0.75rem;
  border: 0;
  border-radius: 9999px;
  background: ${props => props.theme.roomColors[8].darker};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 110%;
  transform: translateX(-50%);
  ${props => props.theme.animations.transitionBase};

  :hover {
    transform: translateX(-50%) translateY(-0.3125rem);
    box-shadow: ${props => props.theme.shadows.high};
  }

  img {
    display: block;
    margin-left: 0.625rem;
  }
`

export default function ChitChatTeaser({ lang }) {
  const intl = useIntl()
  const { openModal } = useModal()
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleUpdate = (msg) => {
    setMessages([msg].concat(messages))
  }

  useEffect(() => {
    getChitChatMessages({ lang, size: 3 })
      .then((res) => {
        setMessages(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  }, [])

  return (
    <Wrapper>     
      {isLoading &&
        <>
          <span className="structural">{intl.formatMessage({ id: "loading" })}</span>
          <ChitChatBubble placeholder />
          <ChitChatBubble placeholder />
          <ChitChatBubble placeholder />
        </>
      }
      {messages.length > 0 &&
        <ul>
          {messages.map((message) => (
            <li key={`${message.id}`}>
              <ChitChatBubble lang={lang} message={message} />
            </li>
          ))}
        </ul>
      }
      <Overlay />
      <Button onClick={(e) => openModal(e, "chitChat", { lang, onUpdate: handleUpdate })}>
        {intl.formatMessage({ id: "open" })} ChitChat
        <img src={chatIcon} alt="" />
      </Button>
    </Wrapper>
  )
}
