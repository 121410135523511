import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  border: 1px solid white;
  background: white;

  @keyframes placeload {
    0% {
      background-position: 60% 0;
    }
    100% {
      background-position: -60% 0;
    }
  }

  .loads {
    animation: placeload 1s linear infinite forwards;
    background: #eee;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 1200px 100px;
  }
`

const Image = styled.div`
  padding-top: 100%;
  background: #ccc;
`

const Inner = styled.div`
  padding: 1rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`

const Logo = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.625rem;
  border-radius: 50%;
`

const Heading = styled.div`
  width: 6.625rem;
  height: 1rem;
  border: solid white;
  border-width: 0.125rem 0 0.0625rem;
`

const Time = styled.div`
  width: 6rem;
  height: 1.125rem;
  border: solid white;
  border-width: 0.1875rem 0;
`

const TextRow = styled.div`
  height: 1.5rem;
  border: solid white;
  border-width: 0.25rem 0;
`

const LinkWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`

const Link = styled.span`
  color: transparent;
`

export default function SocialMediaPostPlaceholder() {
  const intl = useIntl()
  const getTextRow = (width) => <TextRow className="loads" style={{ width: `${width}%` }} />

  return (
    <Wrapper aria-hidden="true">
      <Image className="loads" />
      <Inner>
        <Header>
          <Logo className="loads" />
          <div>
            <Heading className="loads" />
            <Time className="loads" />
          </div>
        </Header>
        {getTextRow(90)}
        {getTextRow(94)}
        {getTextRow(87)}
        {getTextRow(91)}
        {getTextRow(65)}
        <LinkWrapper>
          <Link className="loads">{intl.formatMessage({ id: "socialMediaLinkText" })} Facebook</Link>
        </LinkWrapper>
      </Inner>
    </Wrapper>
  )
}
